<template>
	<div>
		<!-- 面包屑 -->
		<el-breadcrumb separator="/">
			<el-breadcrumb-item>用户管理</el-breadcrumb-item>
			<el-breadcrumb-item>用户列表</el-breadcrumb-item>
		</el-breadcrumb>
		<!-- 卡片区 -->
		<el-card>
			<!-- 列表区域 -->
			<el-tabs type="border-card" style="margin-top:10px">
				<el-tab-pane>
					<template #label>全部</template>
					<el-row>
						<el-col :span="7">
							<el-input placeholder="请输入用户账号" v-model="userQuery.query" @keyup.enter="getUserList()"
								clearable @clear="getUserList()">
								<template #append>
									<el-button icon="el-icon-search" @click="getUserList()"></el-button>
								</template>
							</el-input>
						</el-col>
						<el-col :span="17"></el-col>
					</el-row>
					<el-table :data="userList" stripe border style="width: 100%">
						<el-table-column type="index" label="序号"></el-table-column>
						<el-table-column label="姓名" prop="name"></el-table-column>
						<el-table-column label="用户账号" prop="phone"></el-table-column>
						<el-table-column label="岗位" prop="roleName"></el-table-column>
						<el-table-column label="状态">
							<template v-slot="scope">
								<span v-if="scope.row.status===0">未审核</span>
								<span v-if="scope.row.status===2">已通过</span>
								<span v-if="scope.row.status===1">已拒绝</span>
								<span v-if="scope.row.status===3">已注销</span>
							</template>
						</el-table-column>
						<el-table-column label="操作" min-width="260px">
							<template v-slot="scope">
								<!-- 如果该成员申请注册本公司，且还未通过，才显示此按钮 -->
								<el-button size="mini" type="primary" icon="el-icon-success"
									:disabled="scope.row.status!=0" @click="acceptUser(scope.row)">通过</el-button>
								<el-button size="mini" type="warning" icon="el-icon-error"
									:disabled="scope.row.status!=0" @click="rejectUser(scope.row)">拒绝</el-button>
								<!-- 如果成员属于本公司，才显示删除按钮 -->
								<el-button size="mini" type="danger" icon="el-icon-delete"
									:disabled="scope.row.status!=2" @click="cancelUser(scope.row)">注销</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-config-provider :locale="locale">
						<el-pagination @size-change="handleSizeChange1" @current-change="handleCurrentChange1"
							:current-page="userQuery.pagenum" :page-sizes="[1, 2, 5, 10]"
							:page-size="userQuery.pagesize" layout="total, sizes, prev, pager, next, jumper"
							:total="userTotal"></el-pagination>
					</el-config-provider>
				</el-tab-pane>
				<el-tab-pane>
					<template #label>未审核</template>
					<el-row>
						<el-col :span="7">
							<el-input placeholder="请输入用户账号" v-model="unUserQuery.query"
								@keyup.enter="getUncheckUserList()" clearable @clear="getUncheckUserList()">
								<template #append>
									<el-button icon="el-icon-search" @click="getUncheckUserList()"></el-button>
								</template>
							</el-input>
						</el-col>
						<el-col :span="17"></el-col>
					</el-row>
					<el-table :data="uncheckUserList" stripe border style="width: 100%">
						<el-table-column type="index" label="序号"></el-table-column>
						<el-table-column label="姓名" prop="name"></el-table-column>
						<el-table-column label="用户账号" prop="phone"></el-table-column>
						<el-table-column label="岗位" prop="roleName"></el-table-column>
						<el-table-column label="操作" min-width="260px">
							<template v-slot="scope">
								<el-button size="mini" type="primary" icon="el-icon-success"
									:disabled="scope.row.status!=0" @click="acceptUser(scope.row)">通过</el-button>
								<el-button size="mini" type="warning" icon="el-icon-error"
									:disabled="scope.row.status!=0" @click="rejectUser(scope.row)">拒绝</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-config-provider :locale="locale">
						<el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
							:current-page="unUserQuery.pagenum" :page-sizes="[1, 2, 5, 10]"
							:page-size="unUserQuery.pagesize" layout="total, sizes, prev, pager, next, jumper"
							:total="unUserTotal"></el-pagination>
					</el-config-provider>
				</el-tab-pane>
				<!-- <el-tab-pane>
          <template #label>已审核</template>
          <el-table :data="checkedUserList" stripe border style="width: 100%">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column label="用户账号" prop="phone"></el-table-column>
            <el-table-column label="岗位" prop="role"></el-table-column>
            <el-table-column label="状态">
              <template v-slot="scope">
                <span v-if="scope.row.status===1">已通过</span>
                <span v-if="scope.row.status===2">已拒绝</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" min-width="260px">
              <template v-slot="scope">
                <el-button size="mini" type="danger" icon="el-icon-delete" :disabled="scope.row.status!=1">注销</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane> -->
			</el-tabs>
		</el-card>
	</div>
</template>

<script>
	import zhCn from "element-plus/lib/locale/lang/zh-cn";
	import global_ from '../../components/global.vue'
	export default {
		data() {
			return {
				roleName: "",
				userQuery: {
					query: "",
					pagenum: 1,
					pagesize: 5
				},
				userTotal: 0,
				unUserQuery: {
					curCompanyId: this.$store.getters.getcompanyId,
					query: "",
					pagenum: 1,
					pagesize: 5
				},
				unUserTotal: 0,
				userList: [],
				uncheckUserList: [],
				checkedUserList: [],
			}
		},
		mounted() {
			this.roleName = window.sessionStorage.getItem('roleName')
			// this.getUncheckUserList();
			this.getUserList();
		},
		setup() {
			return {
				locale: zhCn
			};
		},
		methods: {
			async getUserList() {
				let result = null;
				if (this.roleName == "公司管理员") {
					this.userQuery.curItemId = window.sessionStorage.getItem("activeItem")
					const {
						data: res
					} = await this.$http.get("companyItem/getItemMemberList", {
						params: this.userQuery
					});
					if (res.code !== 200) {
						return this.$message.error(res.msg);
					}
					this.userList = res.data.list.slice(1);
					this.userTotal = res.data.total;
					console.log(res)
				} else if (this.roleName == "超级管理员") {
					this.userQuery.curCompanyId = window.sessionStorage.getItem("curCompanyId")
					const {
						data: res
					} = await this.$http.get("user/getUserList", {
						params: this.userQuery
					});
					if (res.code !== 200) {
						return this.$message.error(res.msg);
					}
					// console.log(res)
					this.userList = res.data.list;
					this.userTotal = res.data.total;
					console.log(res)
				}

			},
			async getUncheckUserList() {
				const {
					data: res
				} = await this.$http.get("user/getUncheckUserList", {
					params: this.unUserQuery
				});
				if (res.code !== 200) {
					return this.$message.error(res.msg);
				}
				console.log(res)
				this.uncheckUserList = res.data.list;
				this.unUserTotal = res.data.total;
			},
			async acceptUser(user) {
				const confirmRes = await this.$confirm("确认通过该用户？", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).catch(err => console.log(err));
				if (confirmRes !== "confirm") {
					return this.$message.info("取消通过");
				}
				let formData = new FormData();
				formData.append('id', user.id);
				const {
					data: res
				} = await this.$http.post('user/acceptUser', formData)
				if (res.code !== 200) return this.$message.error(res.msg);
				this.$message.success("该用户申请已通过");
				this.getUserList();
				this.getUncheckUserList();
			},
			async rejectUser(user) {
				const confirmRes = await this.$confirm("确认拒绝该用户？", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).catch(err => console.log(err));
				if (confirmRes !== "confirm") {
					return this.$message.info("取消拒绝");
				}
				let formData = new FormData();
				formData.append('id', user.id);
				const {
					data: res
				} = await this.$http.post('user/rejectUser', formData)
				if (res.code !== 200) return this.$message.error(res.msg);
				this.$message.success("该用户申请已拒绝");
				this.getUserList();
				this.getUncheckUserList();
			},
			async cancelUser(user) {
				const confirmRes = await this.$confirm("确认注销该用户？", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).catch(err => console.log(err));
				if (confirmRes !== "confirm") {
					return this.$message.info("取消注销");
				}
				let formData = new FormData();
				formData.append('id', user.id);
				const {
					data: res
				} = await this.$http.post('user/cancelUser', formData)
				if (res.code !== 200) return this.$message.error(res.msg);
				this.$message.success("该用户已注销");
				this.getUserList();
				this.getUncheckUserList();
			},
			handleSizeChange1(newSize) {
				this.userQuery.pagesize = newSize;
				this.getUserList();
			},
			handleCurrentChange1(newPage) {
				this.userQuery.pagenum = newPage;
				this.getUserList();
			},
			handleSizeChange2(newSize) {
				this.unUserQuery.pagesize = newSize;
				this.getUncheckUserList();
			},
			handleCurrentChange2(newPage) {
				this.unUserQuery.pagenum = newPage;
				this.getUncheckUserList();
			},
		}
	}
</script>
